import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Header from "../pages/layout/Header";
import Sidenav from "../pages/layout/Sidenav";
import Loading from "../utils/Loading";

const privateRoutes = [
  {
    path: "dashboard",
    component: lazy(() => import("../components/dashboard")),
  },
  {
    path: "statistics",
    component: lazy(() => import("../components/dashboard/statistics")),
  },
  {
    path: "members",
    component: lazy(() => import("../components/members")),
  },
  {
    path: "members/:id",
    component: lazy(() => import("../components/members/MemberDetail")),
  },
  {
    path: "kiosk",
    component: lazy(() => import("../components/contents/Kiosk")),
  },
  {
    path: "class",
    component: lazy(() => import("../components/classes/ClassList")),
  },
  {
    path: "class/detail/:id",
    component: lazy(() => import("../components/classes/ClassDetail")),
  },
  {
    path: "create/class",
    component: lazy(() => import("../components/classes/CreateClass")),
  },
  {
    path: "ClassAccept",
    component: lazy(() => import("../components/classes/ClassAccept")),
  },
  {
    path: "stats",
    component: lazy(() => import("../components/classes/ClassStatistics")),
  },
  {
    path: "notice",
    component: lazy(() => import("../components/classes/Notice")),
  },
  {
    path: "notice/detail/:id",
    component: lazy(() => import("../components/classes/NoticeDetail")),
  },
  {
    path: "create/notice",
    component: lazy(() => import("../components/classes/CreateNotice")),
  },
  {
    path: "manager",
    component: lazy(() => import("../components/managers/Admin")),
  },
  {
    path: "manager/detail/:id",
    component: lazy(() => import("../components/managers/AdminDetail")),
  },
  {
    path: "create/managers",
    component: lazy(() => import("../components/managers/CreateAdmin")),
  },
  {
    path: "student",
    component: lazy(() => import("../components/managers/Student")),
  },
  {
    path: "student/:id",
    component: lazy(() => import("../components/managers/StudentDetail")),
  },
  {
    path: "create/students",
    component: lazy(() => import("../components/managers/CreateStudent")),
  },
  { /* 신설학교 */
    path: "foundSchool",
    component: lazy(() => import("../components/managers/FoundSchool")),
  },
  { /* 신설학교 추가 */
    path: "create/foundSchool",
    component: lazy(() => import("../components/managers/CreateFoundSchool"))
  },
  {
    path: "myPage",
    component: lazy(() => import("../components/myAccount")),
  },
  {
    path: "quizPage",
    component: lazy(() => import("../components/quiz")),
  },
  {
    path: "addQuiz",
    component: lazy(() => import("../components/quiz/AddQuiz")),
  },
  {
    path: "question",
    component: lazy(() => import("../components/quiz/question")),
  },
  {
    path: "addQuestion",
    component: lazy(() => import("../components/quiz/addQuestion")),
  },
  {
    path: "messenger",
    component: lazy(() => import("../utils/Chat")),
  },

];

const Routes = ({ match, location, ...otherProps }) => {
  const { url } = match;

  return (
    <Suspense fallback={<Loading />}>
      {location.pathname !== "/main/messenger" && (
        <>
          <Header />
          <Sidenav />
        </>
      )}
      {location.pathname !== "/main/messenger" ? (
        <main>
          <Switch>
            {privateRoutes.map((route) => (
              <Route
                exact
                key={route.path}
                path={`${url}/${route.path}`}
                component={route.component}
                {...otherProps}
              />
            ))}
            <Route component={lazy(() => import("../pages/404"))} />
          </Switch>
        </main>
      ) : (
        <Switch>
          {privateRoutes.map((route) => (
            <Route
              exact
              key={route.path}
              path={`${url}/${route.path}`}
              component={route.component}
              {...otherProps}
            />
          ))}
          <Route component={lazy(() => import("../pages/404"))} />
        </Switch>
      )}
    </Suspense>
  );
};

export default Routes;
